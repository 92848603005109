import { Helmet } from 'react-helmet'
import { useSiteMetadata } from '../../hooks/site'

interface Meta {
  name?: string
  property?: string
  content?: string
}

interface SeoProps {
  description?: string
  lang?: string
  meta?: Meta[]
  title?: string
}

const Seo = ({ description, lang, meta, title }: SeoProps) => {
  const { author, name: siteTitle, description: siteDesc } = useSiteMetadata()

  const metaTitle = title || siteTitle
  const metaDescription = description || siteDesc

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={siteTitle}
      titleTemplate={`%s - ${siteTitle}`}
      meta={(
        [
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: author || ``,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ] as Meta[]
      ).concat(meta || [])}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  title: ``,
  description: ``,
}

export default Seo
