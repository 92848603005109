import classNames from 'classnames'
import { ReactNode } from 'react'
import Container from '../container'
import { page } from './page.module.scss'

interface PageProps {
  container?: boolean
  className?: string
  children: ReactNode
}

const Page = ({ container, children, className }: PageProps) =>
  container ? (
    <Container className={classNames(page, className)}>{children}</Container>
  ) : (
    <div className={classNames(page, className)}>{children}</div>
  )

export default Page
